.main-events {
    background-color: #040D21;
    width: 100%;
    display: flex;
    flex-direction: column;
}


.Events-top {
    width: 85%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-self: center;
    align-self: center;

}

.Events-top-rig {
    display: flex;
    flex-direction: column;
    width: 50%;
    color: white;
}

.Events-top-rig h1 {
    display: flex;
    flex-direction: column;
    width: 546px;
    font-size: 30px;
    font-weight: 500;
    margin: 0 0 0 0;
    font-family: Roboto;
}

.Events-top-rig h2 {
    font-size: 20px;
    font-family: Roboto;
    font-weight: 500;
}

.Events-top-rig h2 span {
    background: -webkit-linear-gradient(-70deg, #ff7170 0%, #ffe57f 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
}

.Events-top-rig p {
    display: flex;
    flex-direction: column;
    width: 580px;
    font-family: Roboto;
    color: #8193b2;
}

.Events-top-rig button {
    width: 210px;
    height: 50px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 600;
    background-color: #fff;
    color: black;
    border: none;
    cursor: pointer;
}

.Events-top-rig button span {
    position: relative;
    top: 2px;
    left: 2px;
}

.Events-top-lef {}

.Events-between {
    width: 100%;
    display: flex;
    padding: 50px 0;
    background-color: #1b1b1b;
    color: white;
    font-family: Roboto;
    align-items: center;
    justify-content: center;
}

.Events-bet-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Events-bet-top p {
    width: 750px;
    font-size: 20px;
    margin: -7px 0 0 0;
    color: #8193b2;
    text-align: left;
    display: flex;
    justify-content: center;
}

.Events-bet-top h1 {
    background: -webkit-linear-gradient(-70deg, #ff7170 0%, #ffe57f 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    font-weight: 500;
    font-size: 30px;
}

.Events-bet-down {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.event-card {

    width: 372px;
    height: 327px;
    display: flex;
    flex-direction: column;
    font-family: Roboto;
}

.prewiew {
    width: 100%;
    height: 209px;
    background-position: center;

}

.prewiew img {
    width: 100%;
    background-position: center;
    height: 209px;
    background-color: #fff;
    object-fit: cover;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

}

.time-share {
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;

}

.time {
    display: flex;
    width: 200px;
    align-items: center;
    justify-content: space-evenly;
}

.time p {
    background: -webkit-linear-gradient(-70deg, #ff7170 0%, #ffe57f 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    font-size: 14px;
    font-weight: 500;
}

.time-share button {
    width: 100px;
    height: 40px;
    color: #fff;
    font-family: Roboto;
    font-weight: 500;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background-color: #414141;
    transition: 0.3s ease-out;


}

.time-share button:hover {
    transform: scale(1.09);
    transition: 0.3s ease-in;
    background-color: #d8a53e;
}

.info {
    background-color: #24201E;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.info h3 {
    margin: 15px 0 10px 15px;
    font-weight: 500;
}





.css-1fbem62 {
    width: 1em;
    height: 1em;
    display: inline-block;
    line-height: 1em;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    color: currentColor;
    vertical-align: middle;
    stroke: #9395A4;
    fill: none;
}

.css-onkibi {
    width: 1em;
    height: 1em;
    display: inline-block;
    line-height: 1em;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    color: #fff;
    vertical-align: middle;
}

.Events-Bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100vh;
    color: white;
    font-family: Roboto;
}

.event-bot-top {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 15%;
    z-index: 1;
    background-color: #141414;
}

.event-bot-top h1 {
    background: -webkit-linear-gradient(-70deg, #ff7170 0%, #ffe57f 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
}

.event-bot-top button {
    width: 200px;
    height: 40px;
    font-size: 16px;
    border: 2px solid #8193b2;
    border-radius: 30px;
    background-color: transparent;
    color: #8193b2;
    margin: 10px 0 5px 0;
}

.foot {
    display: flex;
    align-items: center;
    justify-content: center;
}

.labels {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 50%;
    height: 10vh;
    justify-content: space-evenly;
}

.old-events {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100vh;
    flex-wrap: wrap;
}

.old-event-card {
    display: flex;
    flex-direction: column;
    width: 290px;
    height: 356px;
    margin: 40px 0 0 0;

}

.old-event-card iframe {
    object-fit: contain;
    border: none;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.down-content button {
    width: 200px;
    cursor: pointer;
    height: 50px;
    border-radius: 30px;
    background-color: transparent;
    color: #8193b2;
    border: 2px solid #8193b2;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

.down-content button span {
    margin: 0 0 0 5px;
    font-size: 16px;
}

.down-content h3 {
    margin: 15px 0 15px 15px;
}

.down-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #24201E;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 160px
}

@media screen and (max-width: 480px) {
    .Events-top-rig h1 {
        width: 340px;
    }

    .Events-top-rig p {
        width: 340px;
    }

    .Events-bet-top p {
        width: 360px;
        font-size: 14px;
    }

    .event-bot-top button {
        width: 90px;
        height: 40px;
        font-size: 10px;
        cursor: pointer;
    }

    .event-bot-top {

        width: 100%;
        height: 15vh;
    }

    .event-bot-top h1 {
        width: 80%;
        font-size: 25px;
    }

    .Events-top {
        width: 90%;

    }

    .Events-Bottom {
        height: 1753px;
    }

    .event-card {
        margin: 80px 0px 0px 0px;
        width: 300px;
        height: 327px;
        display: flex;
        flex-direction: column;
        font-family: Roboto;
    }
.Events-between {
    flex-direction: column;
}


}