.center-loader{
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #040D21;
  }
  .ring{
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    animation: ring 2s linear infinite;
  }
  @keyframes ring {
    0%{
      transform: rotate(0deg);
      box-shadow: 1px 5px 2px #e200e6;
    }
    50%{
      transform: rotate(180deg);
      box-shadow: 1px 5px 2px #b26801;
    }
    100%{
      transform: rotate(360deg);
      box-shadow: 1px 5px 2px #030383;
    }
  }
  .ring:before{
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(255,255,255,.3);
  }
  .spann{
    color: #737373;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 200px;
    animation: text 3s ease-in-out infinite;
  }
  /* @keyframes spann {
    50%{
      color: black;
    }
  } */