html{
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: geometricPrecision;
  /* width */
  overflow: overlay;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
 

}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(90, 87, 105, 0.537); 
  
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
