.profiles-main{
  display: flex;
  flex-direction: column;
  width: 100%; 
  background-color: #040D21;
  align-items: center;
}
.profile-top{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 125vh;
 color: white;
 font-family: Roboto;
 
}
.profile-top p{
  color: #8193b2;
}
.profile-top img{
width: 100%;
}
.profile-top h1 {
  font-size: 50px;
  background: -webkit-linear-gradient(-70deg, #ff7170 0%, #ffe57f 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
}
.profile-top p{
line-height: 25px;
  width: 95%;
  /* color: #8193b2; */
}
.profile-between{
  width: 75%;
  display: flex;
  flex-direction: column;
  color: white;
  height: 130vh;
  justify-content: space-around;
  
}.profile-between h1{
  background: -webkit-linear-gradient(-70deg, #ff7170 0%, #ffe57f 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
font-family: Roboto;
margin: 60px 432px 0px 0;


}
.top-prof-bet{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bot-prof-bet{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.personal-info{
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.personal-info p{
  margin: 0;
  color: white;
  font-size: 12px;
  text-decoration: none !important;

  }.personal-info h4{
  margin: 0;
  color: white;
  font-size: 15px;
  text-decoration: none !important;

  }
.our-mission{
width: 700px;
height: 263px;
background-color: #24201E;
border-radius: 30px;
display:flex ;
flex-direction: column;
justify-content: flex-start;
align-items: center;
align-self: flex-end;
}
.profile-between p {
  font-size: 20px;
  width: 600px;
  /* color: #8193b2; */
}
.our-vision{
  width: 700px;
  height: 263px;
  background-color: #24201E;
  border-radius: 30px;
  display:flex ;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.profile-down{
  color: white;
  display: flex;
  /* align-items: center; */
  /* top: 0;  */
  width: 100%;
  background-color: #1b1b1b;
  justify-content: space-evenly;
}
.linkdin{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  
}

.linkdin button{
  position: absolute;
  /* width: 40px;
  height: 40px;
  border-radius: 100%; */
  border: none;
  background-color: #6CA2BB;
  z-index: 1;
  margin: 170px 0 0 0;
}

.tiltee{
  width: 20%;
  position: sticky;
  height: 100vh;
  display: flex;
  align-items: center;
  top: 0;
  margin-left: 10px;
}

.gallery-of-team{
display: flex;
  flex-direction: row;

 justify-self: flex-start;
flex-wrap: wrap;
width: 65%;
position: relative;

}
.profile-down h1 span{
  background: -webkit-linear-gradient(-70deg, #ff7170 0%, #ffe57f 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
}
.profile-card{
  width: 240px;
  height: 310px;
  display: flex;
  flex-direction: column;
  background-color: #24201E;
  color: white;
  align-items: center;
  margin: 0 50px 35px 0px;
  cursor: pointer;
}
.profile-card img{
  object-fit: cover;
  width: 240px;
  height: 250px;
  filter: grayscale(100%);
transition: 0.3s;
z-index: 10;
}

.profile-card img:hover{
  transition: 0.3s;

  filter: none;

}
@media screen and (max-width: 1400px) {
  .profile-between h1 {

    margin: 60px 383px 0px 0;
}
.profile-between p {
  font-size: 20px;
  width: 600px;
  margin: 10px 0 0 10px;
}
.top-prof-bet img{
  height: 150px;
    width: 150px;
}

}
@media screen and (max-width: 940px) {
.our-mission{
  width: 470px;
  height: 185px;

}
.top-prof-bet img{
  width: 80px;
  height: 80px;
  }
  .bot-prof-bet img{
    width: 80px;
  height: 80px;
  }
.our-vision{
  width: 470px;
height: 150px;
}
.profile-between h1 {
  margin: 20px 239px 0px 0;
}
.profile-between p {
  font-size: 14px;
  width: 430px;
  
  margin: 10px 0 0 10px;
}
}
@media screen and (max-width: 650px) {
  .top-prof-bet img{
    display: none;
    
    }
    .bot-prof-bet img{
      display: none;
    
    }
}
@media screen and (max-width: 480px) {

.profile-between{
  display: flex;
  flex-direction: column;
  width: 85%;
  height: 65vh;

}
.our-mission{
  width: 370px;
  display: flex;
  align-self: center;
justify-self: center;
justify-content: center;
}
.our-vision{
  width: 370px;
display: flex;
justify-self: center;
align-self: center;
justify-content: center;

}
.our-vision p {
  width: 90%;
}.our-vision h1 {
  margin: 0 0 0 0;
}
.our-mission p{
  width: 90%;
}
.our-mission h1 {
  margin: 0 0 0 0;
}
.gallery-of-team{
  
  margin: 0 0 0 75px;
}
.profile-down{
  justify-content: space-around;
 width: 100%;
 -webkit-tap-highlight-color: transparent;

}
.profile-card img{
  width: 180px;
}
.profile-card{
  -webkit-tap-highlight-color: transparent;

  margin: 0 0px 35px 0px;
  width: 200px;
  height: 316px;
}
.linkdin button {
  margin: 130px 0 0 0;-webkit-tap-highlight-color: transparent;
}
.profile-top{
  height: 85vh;
}
.profile-top img {
 display: none;
}



.top-prof-bet img{
display: none;

}
.bot-prof-bet img{
  display: none;

}
.top-prof-bet p{
  width: 90%;
  font-size: 14px;
}
.bot-prof-bet p{
  width: 90%;
  font-size: 14px;
}
}