.registations{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: center;
}
.registation-left{
    width: 50%;
        display: flex;
            align-items: center;
            justify-content: center;
}

.registation-right{
    width: 50%;
        display: flex;
            align-items: center;
            justify-content: center;
}

.Registationpage{
    width: 100%;
    height: 100vh;
}