

.mask {
    height: 100px;
    position: relative;
    overflow: hidden;
    width: 75vw;
    margin-top: var(--offset);
  }
  
  .mask span {
    display: block;
    box-sizing: border-box;
    position: absolute;
    top: 100px;
    padding-bottom: var(--offset);
    
    background-size: 100% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-repeat: no-repeat;
  }
  
  .mask span[data-show] {
    transform: translateY(-100%);
    transition: .5s transform ease-in-out;
  }
  
  .mask span[data-up] {
    transform: translateY(-200%);
    transition: .5s transform ease-in-out;
  }
  
  .mask span:nth-child(1) {
    background-image: linear-gradient(45deg, #0ecffe 50%, #07a6f1);
  }
  
  .mask span:nth-child(2) {
    background-image: linear-gradient(45deg, #18e198 50%, #0ec15d);
  }
  
  .mask span:nth-child(3) {
    background-image: linear-gradient(45deg, #8a7cfb 50%, #633e9c);
  }
  
  .mask span:nth-child(4) {
    background-image: linear-gradient(45deg, #fa7671 50%, #f45f7f);
  }


.topbox{
    display: flex;
    /* position: absolute; */
    width: 100%;
    background-color: #fff;
    justify-content: flex-start;
    flex-direction: column;
    flex-direction: column;


}
.Topbox-wrapper{
    width: 100%;
    height: 110vh;
    background-color: #040D21;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
.background-glow {
    width: 100%;
    height: 110vh;
  z-index: 1;
position: absolute;   
/* overflow-x: hidden; */

overflow: hidden;
  }
.background-glow img{
  position: relative;
    top: 50%;
    left: 50%;
    width: 160%;
    transform: translate(-0%, -30%);
   z-index: 1;
  
  

}.background-glow video{
    z-index: 1;
    width: 100%;
    opacity: 0.5;

    transition: 5ms;
    position: absolute;
    object-fit: cover;
    height: 100%;
}
.bottom-curve{
    background-color: #051437;

    width: 100%;
    bottom: 0;
   z-index: 0;
   margin: -5px 0 0 0;
}

.topbox-content{
    position: relative;
    top: -25%;
    width: 85%;
    color: #fff;
    display: flex;
    flex-direction: row;
    height: 500px;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
    z-index: 10;
}.topbox-content h1{
font-size: 100px;
font-family: Helvetica;
font-weight: bold;

}.topbox-content h4{
font-size: 20px;
color: #8193b2;
font-weight: 400;
visibility: hidden;
}
.t-left{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
height: 110%;}
    .t-right{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
}
.t-right img{
    width: 70%;
    border-radius: 30px;
   
}
.t-main-title{
    height: 50%;
}
/* cursor */
.text-change > .txt {
    border-right: 0.2rem solid #777;
    animation:  blink 575ms infinite;
    }
    @keyframes blink {
        to{
            border-right:0.2rem solid transparent;
        }
    }
.mainbutton a{
    color: #fff;
}
    .mainbutton button {
        -webkit-tap-highlight-color: transparent;

        width: 200px;
        height: 50px;
        border: none;
        border-radius: 10px;
        font-size: large;
        letter-spacing: 1px;
         background: #020117;
        color: #fff;
        font-weight: 600;

        font-family: 'Roboto', sans-serif;
        position: relative;
        letter-spacing: 5px;
        background: #040D21;
        cursor: pointer;
        /* filter: blur(0.2px); */
    }
.mainbutton button:hover{
       text-decoration:underline;
      
}.mainbutton button svg{
    width: 24px;
    top: 2px;
    left: 5px;
    position: relative;
      
}
      .mainbutton button::after {
        content: '';
        position: absolute;
        top: -5px;
        left: -5px;
        right: -5px;
        bottom: -5px;
        background: linear-gradient(45deg, #ff5520, #750cf2, #0cbcf2);
        z-index: -1;
        border-radius: 10px;
        opacity: 0.7;
        filter: blur(4px);      }
      .mainbutton button::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(45deg, #ffd800, #ff5520, #750cf2, #0cbcf2);
        z-index: -2;
        filter: blur(40px);
      }
      @media screen and (max-width: 1440px) {
.bottom-curve{
    height: 17vh;
}

.topbox-content h1{
    font-size: 100px;
}
      }     
       @media screen and (max-width: 1180px) {
.topbox-content h1{
    font-size: 90px;
    margin: 115px 0 0 0;
}.topbox-content h4{
    font-size: 18px;
    
}


      }
      @media screen and (max-width: 915px) {
        .topbox-content h1{
            font-size: 70px;
            margin: 130px 0 0 0;
        }
        .mask span{
            top: 100px;
            height: 100px;
        }
        .mask{
            height: 100px;
        }
      }   
         @media screen and (max-width: 820px) {
        .topbox-content h1{
            font-size: 50px;
            margin: 150px 0 0 0;
        }
        .topbox-content h4{
            font-size: 16px;
            
        }
      }

      @media screen and (max-width: 768px) {

        .topbox-content h1 {
            font-size: 50px;
            margin: 150px 0 0 0;
        }
         .topbox-content h4{
             font-size: 16px;
             
         } 
    
        
      }
      @media screen and (max-width: 728px) {
        .topbox-content h1{
            font-size: 50px;
            margin: 150px 0 0 0;
        }
        .topbox-content h4{
            font-size: 15px;
            
        }
      }
      @media screen and (max-width: 636px) {
        .topbox-content h1{
            font-size: 50px;
            margin: 170px 0 0 0;
        }
        .topbox-content h4{
            font-size: 14px;
            
        }
      }

      @media screen and (max-width: 490px) {
   
 .mask{
     height: 100px;
 }
        .mask span{
            top: 125px;
            /* height: 15vh; */
        }
        .topbox-content h1{

            
            font-size: 60px;
            font-family: 'Roboto', sans-serif;
            font-weight: bold;
            width: 70%;
            margin: 70px 0 0 0;
            }
            .t-left{
                width: 100%;
            }
            .topbox-content h4{
                font-size: 17px;
                color: #8193b2;
                font-weight: 400;
                
                }
                .t-right{
                    width: 0%;
                }
                .t-main-title{
                    height: 5%;
                }
                .mainbutton button {
                    top: -90px;
                }
                .background-glow img {
                    position: relative;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    transform: translate(-50%, -50%);
                    z-index: 1;

                }
                /* .bottom-curve{
                    height: 0px;
                } */

      }
      @media screen and (max-width: 385px) {
        .topbox-content{
            top: -10%;
              } 
              .topbox-content h1 {
                  font-size: 50px;
              }
                 
 .mask{
    height: 10.5vh;
}
   .mask span {
       top: 110px;
   }
      }
      @media screen and (max-height: 1390px  ) {
     .topbox-content{
         /* top: -35%; */
     }
    }