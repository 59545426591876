.nav-main{
  /* position: absolute;
  top: -25px;
  left: 0; */
  width: 100%;
  /* height: -1vh; */
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  /* z-index: 1; */
  align-items: center;
  z-index: 10;
}
.navbar_11{
  position: absolute;
  display: flex;
    width: 100%;
    align-items: center;justify-content: center;
    /* flex-direction: column;   */
   

}

.log{
    -webkit-tap-highlight-color: transparent;
  /* -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
}
.log img {
  width: 150px;
  height: 150px;
  position: relative;


  /* border-radius: 100%; */
 
}
.log a {
  text-decoration: none;
  font-size: 40px;
  color: #fff;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
 
}
.log h5{
   margin: 0 0 0 7vw;
   
}
.nav-main ul{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  top: -5px;
}
.nav-main ul li{
  list-style: none;
  line-height: 0px;
  padding: 20px 30px 0 0;
}

.nav-main ul li a{
  display: block;
  height: 200%;
  padding: 0 10px;
  -webkit-tap-highlight-color: transparent;

  text-decoration: none;
  color: #fff;
          font-family: 'Roboto', sans-serif;
          transition: 0.3s ease-in-out;
font-weight: 500;
  font-size: 16px;
}
.nav-main ul li a:hover{
transform: scale(1.2);
transition: 0.3s ease-in-out;

}
.toggle{
   visibility: hidden;
  position: relative;
  width: 54px;
  height: 26px;
  left: -20px;
display: flex;
flex-direction: column;
overflow: hidden;
cursor: pointer;
-webkit-tap-highlight-color: transparent;

}
.bar1{
  margin: auto;
  position: relative;
  width:30px;
  height: 3px;
  background: #fff;
  animation: gradient 5s ease infinite;
  background-size: 400% 400%;
  border-radius: 5px;
  transition: 0.5s;
 
}.bar3{
  margin: auto;
  position: relative;
  width:15px;
  height: 3px;
  background: #fff;
  animation: gradient 5s ease infinite;
  background-size: 400% 400%;
  border-radius: 5px;
  transition: 0.5s;
 
}


.bar2{
  margin: auto;
  position: relative;
  width:22px;
  height: 3px;
  background: #fff;
  animation: gradient 5s ease infinite;
  background-size: 400% 400%;
  border-radius: 5px;
  
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
#hit{
  /* display: none; */
  border: none;
    background: transparent;
}
  @media only screen and (max-width: 700px) {
    .nav-main ul{
      display: none;
    }.logo img {
  width: 90px;
  height: 90px;
  position: relative;
 
  
  /* border-radius: 100%; */
 
}
.nav-main{
  top: 0;
 
}
 .toggle{
   z-index: 10;
  /* visibility: visible; */
  /* top: 60px; */
  /* left: 40%; */
   visibility:visible;
 }


  }
  .aside{
    top: 0;
    position: fixed;
    height: 100%;
    width: 300px;
    display: flex;
 background-color: #040D21;
    z-index: 10;
   transition: 0.5s ;
    left:-300px;
    overflow-y: hidden;

}

.wrapper{
  display: flex;
    justify-content: center;
    width: 300px;
    height: 500px;

    
}
.wrapper ul {

    display: flex;
 flex-direction: column;
 justify-content: space-evenly;
    
}

.wrapper ul li{


    
    list-style: none;
}
.wrapper ul li a:hover{

transition:  ease-in;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.wrapper ul li a{
   display: flex;
   align-items: center;
    text-decoration: none;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
     color: #fff;
     /* background-color: #434242; */
    align-items: center;
    -webkit-tap-highlight-color: transparent;

   width: 185px;
    padding: 18px 25px;

    border-radius: 5px;
    cursor: pointer;
  
}
@media screen and (max-width: 480px) {

  .toggle{
   z-index: 10;
   /* left: 150px; */
   display: flex;
   flex-direction: column;
   overflow: hidden;
   cursor: pointer;
  }
  .navbar_11{
    height: 15vh;
  }
  .log img {
    width: 100px;
    height: 100px;
    position: relative;
    /* border-radius: 100%; */
}
}