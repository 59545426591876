body{
    
}


.Projects{
    width: 100%;
background-color: #040D21;
display: flex;
flex-direction: column;
align-items: center;
}
.Project-top{
width: 90%;
height: 100vh;
}
.project-top-left{
    color: white;
    width: 100%;
    height: 110vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}
.project-top-left button{
    -webkit-tap-highlight-color: transparent;
cursor: pointer;
    width: 200px;
    height: 50px;
    border-radius: 10px;
    border: none;
    font-size: 20px;
    font-weight: 700;
    color: #000;
    background-color: #fff;
}
.project-top-left  h1{
margin: 0;
}
.project-top-left h2 span{
    background: -webkit-linear-gradient(-70deg, #ff7170 0%, #ffe57f 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;}
.project-top-left p{
    width: 500px;
    color: #8193b2;
}
.Project-between{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;

}
.pro-bet-top h2{
    background: -webkit-linear-gradient(-70deg, #ff7170 0%, #ffe57f 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
}
.pro-bet-down{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* font-family: Roboto; */
}
.project-card{
    width: 70%;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
  
    margin-bottom: 80px;
}

.project-title h4{
    margin-bottom: 0;
}
.projectss{
    -webkit-tap-highlight-color: transparent;
margin-left: 125px;
    width: 281px;
    height: 374px;
    display: flex;
    flex-direction: column;
  align-items: center;
  overflow: hidden;
/* justify-content: space-evenly; */
background-color: #24201E;
/* border-radius: 30px; */
border-top-left-radius: 30px;
border-top-right-radius: 30px;
border-bottom-left-radius: 10px;
border-bottom-right-radius: 10px;
margin: 30px 0px 0px 0;
cursor: pointer;
transition: 0.3s ease-in-out;

}
.projectss:hover{
    scale: 1.05;
    transition: 0.3s;
}
.projectss img{
    transition: 0.3s;
}
.projectss img:hover{
    transform: scale(1.2);
    transition: 0.3s ease-in-out;
}
.project-img{
    overflow: hidden;
    
}
.projectss img{
    width: 281px;
    height: 170px;
    object-fit: cover;
}
.description{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 14px;
    width: 95%;
}
.description p{
    color: #8193b2;
font-weight: 400;
}
.team{
    background: -webkit-linear-gradient(-70deg, #ff7170 0%, #ffe57f 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    margin: 15px 0 0 0 ;
}
.team span{

    font-size: 11px;
    font-weight: 700;
}
.Project-bottom{
    position:  fixed;
    /* width: 600px; */
    top: 80px;
    /* left: calc(50% - 300px); */
    /* bottom: 40px; */
    z-index: 100;
    display: flex;
    justify-content: center;
}
@media screen and (max-width: 480px) {
    .project-top-left p {
        width: 370px;
    }
    .project-card{
        width: 100%;
    }
    .project-top-left{
        height: 100vh;
    }
    .project-card{
        flex-direction: column;
    }
}