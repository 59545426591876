.modal-container{
display: flex;
align-items: center;
}
.main-container-modal{
        display: flex;
    width: 70vw;
    height:70vh ;
    background-color: #fff;
    border-radius: 10px;
    justify-content: space-between;
}
.modal-left{
    
 width: 50%;
}
/* .modal-left img{
width: 100%;
height: 100%;
object-fit: cover;
border-bottom-left-radius: 10px;
border-top-left-radius: 10px;

} */
.modal-left video{
width: 100%;
height: 100%;
object-fit: cover;
border-bottom-left-radius: 10px;
border-top-left-radius: 10px;
overflow: hidden;
}
.modal-right{
    width: 60%;
    padding: 10px 40px 10px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}
.modal-right a{
    display: flex;
    justify-self: right;
    align-self: flex-end;
    padding: 0 5px 5px 5px;
}
.pdff{
    width: 100%;
    height: 500px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.pdff embed{
    width: 100%;
    height: 600px;
   
}

.example::-webkit-scrollbar {
    display: none;
    visibility: hidden;
  }
.modal-right hr{
    width: 10%;
    height: 0.5px;
    background-color: rgb(148, 148, 148);
    border-radius: 50px;
   
}
.title-modal{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.title-modal button{
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    height: 35px;
    width: 35px;
    cursor: pointer;
}
@media screen and (max-width: 800px) {
.main-container-modal{
    flex-direction: column;
    width: 90vw;
    height:70vh ;
    justify-content: flex-start;
}
.modal-left video{
    width: 100%;
    height: 25vh;
    object-fit: cover;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    
    }
    .modal-left{
    
        width: 100%;
       }
       .modal-right{
        width: 98%;
        padding: 0px 0px 0px  0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: scroll;
    }
    .modal-right p{
        width: 90%;
    }
    .title-modal button{
        /* margin-right: 10px; */
       
    }
    .title-modal{
        width: 90%;
    }
}
@media screen and (max-width: 400px) {

    .project-top-left p {
        width: 298px;
    }
.modal-left video{
    
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

}