@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@500&family=Libre+Baskerville&family=Montserrat:wght@700&family=Poppins:ital@0;1&display=swap');body{
    background-color: #080710;
}
.background{
    width: 430px;
    height: 520px;
    position: absolute;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
}
.background .shape{
    height: 200px;
    width: 200px;
    position: absolute;
    border-radius: 50%;
}
.shape:first-child{
    background: linear-gradient(
        #1845ad,
        #23a2f6
    );
    left: -80px;
    top: -14px;
}
.shape:last-child{
    background: linear-gradient(
        to right,
        #ff512f,
        #f09819
    );
    right: -71px;
    bottom: -98px;
}
.login-form{
    height: 520px;
    width: 400px;
    background-color: rgba(255,255,255,0.13);
    position: absolute;
    transform: translate(-50%,-50%);
    top: 55%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255,255,255,0.1);
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    padding: 50px 35px;
}
.signup-form{
    background-color: #141627;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    width: 400px;
    padding: 30px;
}
form *{
    font-family: 'Poppins';
    color: #ffffff;
    letter-spacing: 0.5px;
    outline: none;
    border: none;
}
form h3{
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    text-align: left;
}

label{
    display: block;
    margin-top: 18px;
    font-size: 16px;
    font-weight: 500;
}
input{
    display: block;
    height: 50px;
    width: 100%;
    background-color: rgba(255,255,255,0.07);
    border-radius: 7px;
    padding: 0 10px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 300;
}
::placeholder{
    color: #e5e5e5;
}
.login-button{
    width: 100%;
    background-color: #10BB35;
    color: #fff;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    margin: 23px 0;

}
.login-button:hover{
    background-color: #23a2f6;
    transition: 0.9s;
}
.not-account{
    padding:15px 33px 4px 39px;
}
.social{
  display: flex;
  justify-content: center;
}

.social div{
  background: red;
  width: 150px;
  cursor: pointer;;
  border-radius: 3px;
  padding: 5px 10px 10px 5px;
  background-color: rgba(255,255,255,0.27);
  color: #eaf0fb;
  text-align: center;
}
.social div:hover{
  background-color: rgba(255,255,255,0.47);
}
.social .fb{
  margin-left: 25px;
}
.social i{
  margin-right: 4px;
}


.Username{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.Username-right{
display: flex;
width: 47.5%;
align-items: flex-start;
}

.Username-left{
display: flex;
    width: 47.5%;
}

.email{
    display: flex;

}
.phone{
    display: flex;
}
.password{
    display: flex;
}

.react-tel-input .form-control {
    position: relative;
        letter-spacing: .01rem;
        margin-left: 0;
        background: #252636;
        border: none;
        border-radius: 5px;
        line-height: 25px;
        height: 35px;
        width: 100%;
        outline: none;
        padding: 25px;
        padding-left: 45px;
        color: #fff;
}

.react-tel-input{
    margin-top: 8px;
}

.react-tel-input .country-list {
    outline: none;
    z-index: 1;
    list-style: none;
    position: absolute;
    padding: 0;
    margin: 2px 0 10px -1px;
    box-shadow: 1px 2px 10px rgb(0 0 0 / 35%);
    background-color: #252636;
    width: 300px;
    max-height: 200px;
    overflow-y: scroll;
    border-radius: 0 0 3px 3px;
}

.react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: #1e1e1e;
    border: none;
    border-radius: 3px 0 0 3px;
}


.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
    background-color: #080710;
}

.react-tel-input .flag-dropdown.open .selected-flag {
    background: #080710;
    border-radius: 3px 0 0 0;
}


.react-tel-input .country-list .country:hover {
    background-color: #393b48;
}

.react-tel-input .country-list .country.highlight {
    background-color: #393b48;
}

